var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"text-center"},[_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12"}},[_c('h2',{staticClass:"headline font-weight-bold mb-3"},[_vm._v("パスワードの再設定")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[_c('validation-observer',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"メールアドレス","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"メールアドレス","error-messages":errors,"success":valid,"placeholder":"example@example.com","outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}})]}}],null,true)})],1),_c('v-divider',{staticClass:"mt-6"}),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"my-6",attrs:{"color":"primary","loading":_vm.isSend,"disabled":invalid || !validated},on:{"click":function($event){return passes(_vm.sendmail)}}},[_c('v-icon',[_vm._v("mdi-email")]),_vm._v("再設定メール送信 ")],1)],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }