<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3">パスワードの再設定</h2>
        <v-row justify="center">
          <v-col cols="12" sm="10" md="8" lg="6">
            <validation-observer ref="obs" v-slot="{invalid,validated,passes}">
              <v-card flat>
                <v-card-text>
                  <validation-provider
                    name="メールアドレス"
                    rules="required|email"
                    v-slot="{errors,valid}"
                  >
                    <v-text-field
                      v-model="user"
                      label="メールアドレス"
                      :error-messages="errors"
                      :success="valid"
                      v-model.trim="email"
                      placeholder="example@example.com"
                      outlined
                    ></v-text-field>
                  </validation-provider>
                </v-card-text>
                <v-divider class="mt-6"></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn
                    class="my-6"
                    color="primary"
                    :loading="isSend"
                    @click="passes(sendmail)"
                    :disabled="invalid || !validated"
                  >
                    <v-icon>mdi-email</v-icon>再設定メール送信
                  </v-btn>
                </v-card-actions>
              </v-card>
            </validation-observer>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
export default {
  name: "changepasword",
  data: () => ({
    email: "",
    show1: false,
    isSend: false
  }),
  methods: {
    sendmail() {
      if (this.isSend) {
        return false;
      }
      this.isSend = true;
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          window.alert(
            "再設定のメールを送付いたしました。ご確認お願い致します。"
          );
          this.isSend = false;
          return;
        })
        .catch(error => {
          this.isSend = false;
          if (error.code === "auth/invalid-email") {
            window.alert("メールアドレスが有効ではありません。");
            window.alert("メールアドレスが有効ではありません。" + this.email);
            return;
          }
          if (error.code === "auth/user-not-found") {
            window.alert(
              "電子メールアドレスに該当するユーザーはありません" + this.email
            );
            return;
          }
          window.alert(error.message);
        });
    }
  }
};
</script>
