<template>
  <the-change-password />
</template>

<script>
// @ is an alias to /src
import TheChangePassword from "@/components/TheChangePassword.vue";

export default {
  name: "ChangePassword",
  components: {
    TheChangePassword
  }
};
</script>
